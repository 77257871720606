.main-container {
    align-items: center;
    color: #3E5E53;
    margin-top: 15vh;
    margin-bottom: 15vh;
}

.search-bar-container {
    margin-bottom: 2vh;
}

.button {
    color: #3E5E53;
    border: none;
    height: 4.25vh;
    transition: 0.3s;
    border-radius: 0.25rem;
}

.search {
    background-color: transparent;
}

.button:hover {
    background-color: rgb(189, 187, 187);
}

.button:disabled {
    opacity: 0.6;
    
}

.search-button:hover {
    background-color: rgb(187, 186, 186);
}