.image-gallery-image {
    position: relative;
  display: flex;
  height: auto;
  width: auto;
}
  
  .delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(220, 53, 69, 0.8);
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 18px;
  }
  
  .delete-button:hover {
    background: rgba(220, 53, 69, 1);
  }
  
.photo-upload-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.photos-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.photo-item {
  position: relative;
  width: 200px;
  height: 200px;
  flex-shrink: 0;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.upload-button-container {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.custom-file-upload {
  display: inline-block;
  padding: 12px 24px;
  background: #3E5E53;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: 500;
}

.custom-file-upload:hover {
  background: #2c443b;
  transform: translateY(-1px);
}
