.header {
  top: 0;
  position: fixed;
  width: 100vw;
  color: #3E5E53;
  font-size: calc(7px + 1vmin);
  background-color: white;
  z-index: 1000;
}

.header-logo {
  padding-right: 5%;
}

.log-out{
  display: table-cell;
  cursor: pointer;
}

.log-out-div{
  display: table;
}

