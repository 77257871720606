.photos-element-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
}

.meta-element-label {
    width: 15% !important;
}

.element-meta-data {
    width: 90% !important;
    align-self: center;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 10%;
}

.trail-checkbox-group-container {
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.trail-checkbox-group-container .form-group {
    margin-bottom: 0 !important;
}

.trail-checkbox-group-container .form-check {
    padding-left:2rem;
}