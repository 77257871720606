.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #3E5E53;
    padding-top: 10vh;
    margin-top: -1vh;
  }

.review-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-x: auto;
}
  