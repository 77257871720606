.waypoint-card-container{
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    max-height: 80vh;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-bottom: 1rem;
}

.actions{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}
