/* Sidebar.css */
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    background-color: #3E5E53;
    color: white;
    padding: 15px;
    overflow-y: auto;
    padding-top: 6%;
    height: 100%;
}

.sidebar .nav-link {
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 10px;
    transition: all 0.3s ease;
}

.sidebar .nav-link:hover {
    color: #d44100;
}

.sidebar .nav-link.active {
    background-color: #d44100;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 10px;
}

.add-waypoint-button {
    background-color: #d44100;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    cursor: pointer;
}
