.trail-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    bottom: 1%;
}

.trail-sub-data {
    display: flex;
    flex-direction: column;
}

.trail-action-btn {
    width: auto;
    margin-right: 1%;
    font-size: small;
}


