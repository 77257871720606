.log-in-form{
    min-height: 35vh;
    width: 100%;
    border-radius: 10%;
    max-width: 45vh;
    margin-top: 12%;
    margin: 0 auto;
}

.log-in-main{
    margin-top: 10%;
}

.log-in-submit{
}

.form-sign-up-labels {
}

@media screen and (max-width: 765px) {    
    .log-in-submit{
        text-align: center;
    }

    .log-in-form {
    }

    .form-sign-up-labels {
        margin-left: auto;
        margin-right: auto;
    }

}