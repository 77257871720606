.app{
  background-image: url("./Assets/background.png");
  width: 100%;
  overflow-y: auto;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 50vh !important;
  min-height: 50vh;
}


.root-view {
  display: flex;
  height: 100vh;
  overflow: hidden;
}


.app-content {
  flex: 1;
  margin-left: 250px; 
  overflow-y: auto;
  padding-left: 20px;
  height: 95%;
  margin-top: 1%;
}
